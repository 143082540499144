import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';
import { UserDetails } from 'src/app/types/user.type';
import { StyleManager } from '../shared/style-manager';

@Component({
  selector: 'itfg-nav-split-button',
  templateUrl: './nav-split-button.component.html',
  styleUrls: ['./nav-split-button.component.scss'],
})
export class NavSplitButtonComponent {
  showCopyRefferalLinkButton = false;
  showInvitationLink = false;

  constructor(
    public auth: AuthService,
    private notifications: NotificationService,
    private clipboard: Clipboard,
    private router: Router
  ) {
    this.auth.currentUserBus$.asObservable().subscribe((user: UserDetails) => {
      this.showCopyRefferalLinkButton = !user.isMedifin;
      this.showInvitationLink = user.isMedifin;
    });
  }

  async copyRefferalLink() {
    const refLink =
      environment.WEBSITE_SC_URL +
      '?partner_code=' +
      this.auth.currentUser?.code;
    this.clipboard.copy(refLink);
    this.notifications.showLocalizedSuccessMessage({
      notificationText: 'Линкът е копиран успешно!',
    });
  }

  logout() {
    this.auth.logout$().subscribe(() => {
      this.router.navigate(['/auth']);
    });
  }
}
