<!-- contact-us.component.html -->
<div class="form-layout">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <span>Контакти</span>
        </mat-card-title>
        <mat-card-subtitle>Свържете се с нас за всякакви въпроси или технически проблеми</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="contact-content">
        <div class="contact-info">
          <mat-icon color="primary">phone</mat-icon>
          <div class="contact-details">
            <span class="contact-label">Телефон:</span>
            <a href="tel:+35929042429" class="primary-text">+359 2 904 2429</a>
          </div>
        </div>
  
        <div class="contact-info">
          <mat-icon color="primary">email</mat-icon>
          <div class="contact-details">
            <span class="contact-label">Имейл:</span>
            <a href="mailto:dev&#64;itfgroupbg.com" class="primary-text">dev&#64;itfgroupbg.com</a>
          </div>
        </div>
  
        <div class="additional-info">
          <p>За техническа поддръжка или други въпроси, може да ни пишете на посочения имейл или да ни се обадите на телефона.</p>
          <p>Работно време: Понеделник - Петък, 08:00 - 20:00</p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>