import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'itfg-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }

}
