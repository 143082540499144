import { Injectable } from '@angular/core';
import { CustomFormFieldBase } from './dynamic-form-control-types/custom-form-field.type';
import { InputBoxFormControl } from './dynamic-form-control-types/form-field-inputbox';
import { Observable, of } from 'rxjs';
import { ProductPlanSliderFormControl } from './dynamic-form-control-types/form-field-product-plan-slider.type';
import {
  ApplicationFormFieldType,
  BrandCodes,
} from '../../../types/application.type';
import {
  isValidEgn,
  isValidIban,
  isValidIdCard,
  isValidIdEmail,
} from 'src/app/validation/itfg-validation';
import { Validators } from '@angular/forms';
import { DropdownFormControl } from './dynamic-form-control-types/form-field-dropdown.type';
import { CreditsService } from 'src/app/services/credits.service';
import { AutocompleteFormControl } from './dynamic-form-control-types/form-field-autocomplete.type';
import { AuthService } from 'src/app/services/auth.service';
import { ImageUploadFormControl } from './dynamic-form-control-types/form-field-image-upload.type';
import { UtilsService } from 'src/app/services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationCreateFormFieldsService {
  private static phonePrefix = '+359';

  constructor(
    private credits: CreditsService,
    private auth: AuthService,
    private utils: UtilsService
  ) {}

  private FORM_FIELD_TEMPLATES = {
    [ApplicationFormFieldType.PRODUCT_PLAN]: new ProductPlanSliderFormControl({
      key: 'product_plan',
    }),
    [ApplicationFormFieldType.EMAIL]: new InputBoxFormControl({
      key: 'email',
      label: 'Имейл',
      type: 'email',
      placeholder: 'example@gmail.com',
      floatLabel: 'always',
      preffixIcon: 'alternate_email',
      value: null,
      required: this.auth.currentUser?.brand.code === BrandCodes.MF,
      validators: [isValidIdEmail],
      payloadParserCallback: (inputEmail: string) => inputEmail || null,
    }),
    [ApplicationFormFieldType.NAME]: new InputBoxFormControl({
      key: 'name',
      label: 'Пълно име',
      floatLabel: 'always',
      preffixIcon: 'person',
      value: '',
      required: true,
      payloadParserCallback: this.utils.parseFullName,
    }),
    [ApplicationFormFieldType.CIVIL_ID]: new InputBoxFormControl({
      key: 'civilId',
      label: 'ЕГН',
      placeholder: 'Попълнете ЕГН',
      floatLabel: 'always',
      preffixIcon: 'person_pin',
      value: null,
      required: true,
      validators: [isValidEgn],
    }),
    [ApplicationFormFieldType.MOBILE_NUMBER]: new InputBoxFormControl({
      key: 'mobile',
      label: 'Мобилен телефон',
      type: 'tel',
      placeholder: '99999999',
      prefixText: '+359',
      floatLabel: 'always',
      preffixIcon: 'local_phone',
      value: '',
      required: true,
      payloadParserCallback: (mobileNumber: string) =>
        ApplicationCreateFormFieldsService.phonePrefix + mobileNumber,
      validators: [Validators.pattern(/^[0-9]{9}$/)],
    }),
    [ApplicationFormFieldType.ID_CARD_NUMBER]: new InputBoxFormControl({
      key: 'idCardNumber',
      label: 'Номер на лична карта',
      floatLabel: 'always',
      preffixIcon: 'tag',
      value: null,
      required: true,
      validators: [isValidIdCard],
    }),
    [ApplicationFormFieldType.EDUCATION]: new DropdownFormControl({
      key: 'education',
      label: 'Образование',
      placeholder: 'Избери от списъка',
      floatLabel: 'always',
      value: null,
      preffixIcon: 'tag',
      optionsBus$: this.credits.getEducationTypeList$(),
      required: true,
    }),
    [ApplicationFormFieldType.EMPLOYMENT_TYPE]: new DropdownFormControl({
      key: 'employment_type',
      label: 'Вид заетост',
      placeholder: 'Избери от списъка',
      floatLabel: 'always',
      value: null,
      preffixIcon: 'tag',
      optionsBus$: this.credits.getEmploymentTypeList$(),
      required: true,
    }),
    [ApplicationFormFieldType.NATIONALITY]: new AutocompleteFormControl({
      key: 'nationality',
      label: 'Гражданство',
      placeholder: 'Избери от списъка',
      floatLabel: 'always',
      value: null,
      preffixIcon: 'tag',
      optionsBus$: this.credits.getCountriesTypeList$(),
      required: true,
    }),
    [ApplicationFormFieldType.PAYMENT_METHOD]: new AutocompleteFormControl({
      key: 'payment_method',
      label: 'Метод на плащане',
      placeholder: 'Избери от списъка',
      floatLabel: 'always',
      value: null,
      preffixIcon: 'tag',
      optionsBus$: this.credits.getPaymentMethodList$(
        this.auth.currentUser?.brand.code
      ),
      required: true,
    }),
    [ApplicationFormFieldType.PAYMENT_METHOD_MEDIFIN]: new DropdownFormControl({
      key: 'payment_method',
      label: 'Метод на плащане',
      placeholder: 'Избери от списъка',
      floatLabel: 'always',
      value: 1,
      preffixIcon: 'payments',
      options: [
        { id: 1, name: 'Банкова сметка на партньора' },
        { id: 2, name: 'Банкова сметка на клиента' },
      ],
      required: true,
    }),
    [ApplicationFormFieldType.IBAN]: new InputBoxFormControl({
      key: 'iban',
      label: 'Банкова сметка',
      floatLabel: 'always',
      value: this.auth.currentUser?.iban || null,
      preffixIcon: 'account_balance',
      required: true,
      validators: [isValidIban],
    }),
    [ApplicationFormFieldType.RELATED_PERSON_NAME]: new InputBoxFormControl({
      key: 'name',
      label: 'Име на лице за контакт',
      floatLabel: 'always',
      preffixIcon: 'person',
      value: null,
      required: true,
    }),
    [ApplicationFormFieldType.RELATED_PERSON_MOBILE_NUMBER]:
      new InputBoxFormControl({
        key: 'mobile',
        label: 'Мобилен телефон на лицето за контакт',
        type: 'tel',
        placeholder: '99999999',
        prefixText: '+359',
        floatLabel: 'always',
        preffixIcon: 'local_phone',
        value: null,
        required: true,
      }),
    [ApplicationFormFieldType.ID_FRONT]: new ImageUploadFormControl({
      key: ApplicationFormFieldType.ID_FRONT,
      label: 'Лична карта - лице',
      floatLabel: 'always',
      placeholder: '.jpeg, .gif, .png',
      preffixIcon: 'image',
      required: false,
    }),
    [ApplicationFormFieldType.ID_BACK]: new ImageUploadFormControl({
      key: ApplicationFormFieldType.ID_BACK,
      label: 'Лична карта - гръб',
      floatLabel: 'always',
      placeholder: '.jpeg, .gif, .png',
      preffixIcon: 'image',
      required: false,
      validators: [],
    }),
    [ApplicationFormFieldType.SELFIE]: new ImageUploadFormControl({
      key: ApplicationFormFieldType.SELFIE,
      label: 'Селфи с лична карта',
      floatLabel: 'always',
      captureMode: 'user',
      placeholder: '.jpeg, .gif, .png',
      preffixIcon: 'image',
      required: false,
    }),
  };

  get(type: ApplicationFormFieldType) {
    return this.FORM_FIELD_TEMPLATES[type];
  }

  getFormFields$(
    includedFields: ApplicationFormFieldType[] = []
  ): Observable<CustomFormFieldBase<string>[]> {
    const formFields: CustomFormFieldBase<string>[] = [];
    for (let fieldToInclude of includedFields) {
      formFields.push(this.FORM_FIELD_TEMPLATES[fieldToInclude]);
    }
    return of(formFields);
  }
}
