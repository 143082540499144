import { CreditInstallment } from './credit.type';
import { Invitation } from './invitation.type';
import { Product, ProductPeriodUnit } from './product-plan.type';
import { UserDetails } from './user.type';

export interface CreateApplicationDto {
  product: string;
  product_id?: number;
  description?: string;
  period: number;
  principal: number;
  firstName: string;
  middleName: string;
  lastName: string;
  civilId: string;
  idCardNumber: string;
  mobile: string;
  email: string;
  iban: string;
  ID_FRONT?: string;
  ID_BACK?: string;
  SELFIE?: string;
}

export interface CreateApplicationInvitationDto {
  principal: number;
  email: string;
  product_id: number;
  description: string;
}

export interface Credit {
  id: number;
  civilId: string;
  clientName: string;
  createTime: Date;
  email: string;
  idCardNumber: string;
  installmentAmount: number;
  mobile: string;
  period: number;
  principal: number;
  product: string;
  periodUnit: ProductPeriodUnit;
  status: CreditStatusNames;
  currentInstallment: CreditInstallment | null;
}

export interface ApplicationRequest {
  id: number;
  civilId: string;
  clientName: string;
  createTime: Date;
  email: string;
  mobile: string;
  period: number;
  principal: number;
  product: string;
  periodUnit: ProductPeriodUnit;
  status: string;
  idCardNumber: string;
}

export interface ApplicationRaw {
  credit: Credit;
  request: ApplicationRequest;
}

export enum ApplicationFormFieldType {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  CIVIL_ID = 'CIVIL_ID',
  PRODUCT_PLAN = 'PRODUCT_PLAN',
  MOBILE_NUMBER = 'MOBILE_NUMBER',
  ID_CARD_NUMBER = 'ID_CARD_NUMBER',
  NATIONALITY = 'NATIONALITY',
  EDUCATION = 'EDUCTION',
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  IBAN = 'IBAN',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PAYMENT_METHOD_MEDIFIN = 'PAYMENT_METHOD_MEDIFIN',
  RELATED_PERSON_NAME = 'RELATED_PERSON_NAME',
  RELATED_PERSON_MOBILE_NUMBER = 'RELATED_PERSON_MOBILE_NUMBER',
  ID_FRONT = 'ID_FRONT',
  ID_BACK = 'ID_BACK',
  SELFIE = 'SELFIE',
}

export enum CreditStatusNames {
  APPROVED = 'APPROVED',
  CONFIRMED = 'CONFIRMED',
  LOSS = 'LOSS',
  NEW = 'NEW',
  OVERDUE = 'OVERDUE',
  REGULAR = 'REGULAR',
  REJECTED = 'REJECTED',
  REPAID = 'REPAID',
}

export enum BrandCodes {
  MF = 'MF',
  SC = 'SC',
}

export class DashBoardListData<T> {
  id: number;
  principal: number;
  createTime: Date;
  status: string;
  note: string;
  email: string;
  product: string;
  application: ApplicationParsedView;
  invitation: Invitation;

  constructor(entity: T) {
    if (entity instanceof ApplicationParsedView) {
      this.setFromApplicationParsedView(entity);
    } else if (entity instanceof Invitation) {
      this.setFromInvitation(entity);
    }
  }

  setFromApplicationParsedView(entity: ApplicationParsedView) {
    this.id = entity.id;
    this.principal = entity.principal;
    this.email = entity.email;
    this.createTime = entity.createTime;
    this.status = entity.status;
    this.product = entity.product;
    this.note = entity.clientName;
    this.application = entity;
  }

  setFromInvitation(entity: Invitation) {
    this.id = entity.id;
    this.principal = entity.principal;
    this.email = entity.email;
    this.createTime = entity.createdAt;
    this.status = entity.status.name;
    this.product = entity.product.name;
    this.note = entity.description;
    this.invitation = entity;
  }
}

export enum UserVerificationState {
  VALID = 'VALID',
  PROCESSING = 'PROCESSING',
  INVALID = 'INVALID',
}

export class ApplicationParsedView {
  raw: ApplicationRaw;
  id: number = 0;
  civilId: string = '';
  clientName: string = '';
  _createTime: Date = new Date();
  email: string = '';
  idCardNumber: string = '';
  installmentAmount: number = 0;
  mobile: string = '';
  period: number = 0;
  principal: number = 0;
  product: string = '';
  status: string = '';
  dueDate: Date = null;
  idCardVerified: boolean = false;
  userVerificationState: UserVerificationState = null;
  contractsHidden: boolean = false;
  isApprovedCredit: boolean = false;
  filledByPartner: boolean = false;
  approvedByPartner: boolean = false;
  rejectedByPartner: boolean = false;
  awaitingApprovalByPartner = false;
  periodUnit: string = '';
  tags?: string[] = [];

  set createTime(date: Date | string) {
    if (typeof date === 'string') {
      this._createTime = new Date(date);
    } else {
      this._createTime = date;
    }
  }

  get createTime(): Date {
    return this._createTime;
  }

  get verification(): { [key in UserVerificationState]: boolean } {
    return {
      [UserVerificationState.VALID]:
        this.userVerificationState === UserVerificationState.VALID,
      [UserVerificationState.PROCESSING]:
        this.userVerificationState === UserVerificationState.PROCESSING,
      [UserVerificationState.INVALID]:
        this.userVerificationState === UserVerificationState.INVALID,
    };
  }

  constructor(applicationRaw?: ApplicationRaw) {
    this.raw = applicationRaw;
    Object.assign(this, this.raw.credit || this.raw.request);
    this.setAdditionalFields();
  }

  applyUserSpecificMapping(user: UserDetails) {
    if (!user) {
      return;
    }
    if (user.isMedifin && this.inStatus(CreditStatusNames.CONFIRMED)) {
      if (this.approvedByPartner) {
        this.status = 'APPROVED_BY_PARTNER';
      } else if (this.rejectedByPartner) {
        this.status = 'REJECTED_BY_PARTNER';
      } else {
        this.status = 'AWAITING_APPROVAL_BY_PARTNER';
        this.awaitingApprovalByPartner = true;
      }
    }
  }

  setAdditionalFields() {
    this.isApprovedCredit =
      this.isCredit() && this.inStatus(CreditStatusNames.APPROVED);
    this.filledByPartner = !!this.raw.request;
    if (
      !this.raw.credit &&
      !!this.raw.request &&
      this.raw.request.status === 'COMPLETED'
    ) {
      this.status = 'REJECTED';
    }
    this.contractsHidden = !this.isApprovedCredit || !this.filledByPartner;
    this.approvedByPartner = this.tags.includes('APPROVED_BY_PARTNER');
    this.rejectedByPartner = this.tags.includes('REJECTED_BY_PARTNER');
    if (!!this.raw?.credit) {
      this.periodUnit = this.raw?.credit?.periodUnit;
    } else {
      this.periodUnit = this.product === 'PAYDAY' ? 'DAY' : 'MONTH';
    }

    // Temporary map verification state from idCardVerified
    // Delete when KYC is deployed to prod
    if (this.userVerificationState === null) {
      if (this.idCardVerified) {
        this.userVerificationState = UserVerificationState.VALID;
      } else if (this.idCardVerified === null) {
        this.userVerificationState = UserVerificationState.PROCESSING;
      } else {
        this.userVerificationState = UserVerificationState.INVALID;
      }
    }
  }

  inStatus(statusToCheck: CreditStatusNames) {
    return this.status === statusToCheck;
  }

  private isCreditIsInStatusGroup(statusGroup: CreditStatusNames[]) {
    return !!this.raw.credit && statusGroup.includes(this.raw.credit.status);
  }

  isUtilized() {
    const utilizedCreditStatusNames = [
      CreditStatusNames.REGULAR,
      CreditStatusNames.OVERDUE,
      CreditStatusNames.REPAID,
    ];
    return this.isCreditIsInStatusGroup(utilizedCreditStatusNames);
  }

  isCredit() {
    const applicationStatusNames = [
      CreditStatusNames.NEW,
      CreditStatusNames.REJECTED,
    ];
    return !this.isCreditIsInStatusGroup(applicationStatusNames);
  }

  isActiveCredit() {
    const activeStatuses = [
      // CreditStatusNames.NEW,
      CreditStatusNames.APPROVED,
      CreditStatusNames.CONFIRMED,
      CreditStatusNames.REGULAR,
      CreditStatusNames.OVERDUE,
      CreditStatusNames.REPAID,
    ];

    return this.isCreditIsInStatusGroup(activeStatuses);
  }
}
